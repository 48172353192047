<template>
    <v-card>
        <div class="modal">
            <div class="modal-content">
                <s-toolbar
                    label="Impresion de Recepcion de Fruta"
                    dark
                    :color="'#BAB6B5'"
                    close
                    @close="$emit('close')"
                    class="mb-6"
                    print @print="downloadPdf()"
                ></s-toolbar>
                <!-- <v-card-title style="font-size: small;">
                    <v-row>					
                        <v-col align="right">
                            <v-btn 
                                fab 	
                                x-small
                                color="info"
                                @click="downloadPdf()"
                            >	<v-icon medium>mdi-printer</v-icon>				
                            </v-btn>
                        </v-col>
                    </v-row>	
                </v-card-title> -->
                
                    <!-- <vue-html2pdf
                    :show-layout="true"
                    :float-layout="false"
                    :enable-download="false"
                    :preview-modal="true"
                    :filename="'Guia-'"
                    :paginate-elements-by-height="1200"
                    :pdf-quality="2"
                    pdf-format="a4"
                    pdf-orientation="portrait"
                    pdf-content-width="800px" 
                    :manual-pagination="true"
                    :margin="1"
                    @progress="onProgress($event)"
                    @startPagination="startPagination()"
                    @hasPaginated="hasPaginated()"
                    @beforeDownload="beforeDownload($event)"
                    @hasDownloaded="hasDownloaded($event)" ref="html2Pdf">	
                    <section slot="pdf-content"> 
                        <v-container >                    
                            <v-row >
                                <v-col cols="6" style="margin: 0px;padding: 0px;">                             
                                    <img width='190px' src='../../../../public/static/logo_sp.png' 
                                        /> 
                                        <p style="margin:0px;padding:2px;font-family:Arial Black, Helvetica, sans-serif;font-size: 10px;font-weight:bold;text-align:justify;">SUNSHINE EXPORT S.A.C</p>
                                        <p style="margin:0px;padding:2px;font-family:Arial Black, Helvetica, sans-serif;font-size: 8px;font-weight:bold;text-align:justify;">CAR. TAMBOGRANDE - LAS LOMAS KM 1077 (CAR. TAMBOGRANDE - LAS LOMAS KM 1077+179M)</p>
                                        <p style="margin:0px;padding:2px;font-family:Arial Black, Helvetica, sans-serif;font-size: 8px;font-weight:bold;text-align:justify;">PIURA - PIURA - TAMBOGRANDE - Telf.: 073-368262</p>
                                        <p style="margin:0px;padding:2px;font-family:Arial, Helvetica, sans-serif;font-size: 8px;font-weight:bolder;">Car. Panamericana Sur Nro. 133 Lima - Cañete - Cerro Azul</p>
                                        
                                </v-col>
                                <v-col cols="auto" >                                
                                    <p style="font-family:Arial Black, Helvetica, sans-serif;font-size: 16px;font-weight: bold;text-align: center;">R.U.C. N° 20329725431</p>                                
                                    <p style="margin:0px;padding:5px;font-family:Arial Black, Helvetica, sans-serif;font-size: 12px;font-weight: bold;text-align: center;">RECEPCIÓN DE FRUTA </p>                               
                                </v-col>
                            </v-row>
                            
                            <v-row >
                                <v-col >                        
                                    <table class="table-container" >
                                        <thead class="table-header">
                                            <tr>   
                                                <th >Nom. Proveedor</th>
                                                <th >DNI/Ruc</th>
                                                <th >Guia</th>
                                                <th >Descrip.</th>
                                                <th >Placa Transp.</th>
                                                <th >Entrada N°.</th>
                                                <th >Fecha</th>
                                                <th >Num. Jabas</th>
                                                <th >Fundo</th>
                                                <th >Peso Bruto.</th>                                                                                                                                  
                                            </tr>
                                        </thead>
                                        <tbody class="table-body">
                                            <tr v-for="(item, index) in referralGuideSelected" :key="index">
                                            
                                                <td >{{ item.PrdCardName }}</td> 
                                                <td >{{ item.PrdCardCode }}</td>
                                                <td >{{ item.RcfNumberHarvestGuide }}</td>
                                                <td >{{ item.TypeCultiveName + ' ' + item.TypeCropName + ' ' + item.VrtName}}</td> 
                                                <td >{{ item.TptEnrollment }}</td>
                                                <td >{{ item.RcfID }}</td>
                                                <td >{{ formatDate(item.RcfDateReception) }}</td> 
                                                <td >{{ item.AmountJabasWeigh }}</td> 
                                                <td >{{ item.FagName }}</td> 
                                                <td >{{ item.RcfWeightGuide }}</td>  
                                            
                                            </tr>  
                                        </tbody>
                                    
                                    </table>
                                </v-col>
                            </v-row>
                        </v-container>                    
                    </section>
                </vue-html2pdf> -->
                <div id="testHtml" ref="testHtml">
                    <div>
                        <div>
                            <img  width="200px"
                                
                                src="../../../../public/static/logo_sp.png"
                                alt="User Image"
                                ref="userImage"/> 
                            
                        </div>
                        <div style="align-items: center;">
                            <table class="table-header">
                                <tr>
                                    <td>
                                        <p style="margin:0px;padding:2px;font-family:Arial Black, Helvetica, sans-serif;font-size: 10px;font-weight:bold;text-align:justify;">SUNSHINE EXPORT S.A.C</p>
                                        <p style="margin:0px;padding:2px;font-family:Arial Black, Helvetica, sans-serif;font-size: 8px;font-weight:bold;text-align:justify;">CAR. TAMBOGRANDE - LAS LOMAS KM 1077 (CAR. TAMBOGRANDE - LAS LOMAS KM 1077+179M)</p>
                                        <p style="margin:0px;padding:2px;font-family:Arial Black, Helvetica, sans-serif;font-size: 8px;font-weight:bold;text-align:justify;">PIURA - PIURA - TAMBOGRANDE - Telf.: 073-368262</p>
                                        <!-- <p style="margin:0px;padding:2px;font-family:Arial, Helvetica, sans-serif;font-size: 8px;font-weight:bolder;">Car. Panamericana Sur Nro. 133 Lima - Cañete - Cerro Azul</p> -->
                                    </td>
                                    <td>
                                        <p style="font-family:Arial Black, Helvetica, sans-serif;font-size: 16px;font-weight: bold;text-align: center;">R.U.C.</p>    
                                        <p style="font-family:Arial Black, Helvetica, sans-serif;font-size: 16px;font-weight: bold;text-align: center;">N° 20329725431</p>                             
                                        <p style="margin:0px;padding:5px;font-family:Arial Black, Helvetica, sans-serif;font-size: 12px;font-weight: bold;text-align: center;">RECEPCIÓN DE FRUTA </p>                               
                                    </td>
                                </tr>
                               
                            </table>                          
                        </div>
                        <br/>
                        <div style="align-items: center;">
                            <table class="table-container" >
                                <thead class="table-header">
                                    <tr>   
                                        <th >Nom. Proveedor</th>
                                        <th >DNI/Ruc</th>
                                        <th >Guia</th>
                                        <th >Descrip.</th>
                                        <th >Placa Transp.</th>
                                        <th >Entrada N°.</th>
                                        <th >Fecha</th>
                                        <th >Num. Jabas</th>
                                        <th >Fundo</th>
                                        <th >Peso (Kg).</th>                                                                                                                                  
                                    </tr>
                                </thead>
                                <tbody class="table-body">
                                    <tr v-for="(item, index) in referralGuideSelected" :key="index">                                    
                                        <td >{{ item.PrdCardName }}</td> 
                                        <td >{{ item.PrdCardCode }}</td>
                                        <td >{{ item.RcfNumberReferralGuide }}</td>
                                        <td >{{ item.TypeCultiveName + ' ' + item.TypeCropName + ' ' + item.VrtName}}</td> 
                                        <td >{{ item.TptEnrollment }}</td>
                                        <td >{{ item.RcfID }}</td>
                                        <td >{{ formatDate(item.RcfDateReception) }}</td> 
                                        <td >{{ item.AmountJabasWeigh }}</td> 
                                        <td >{{ item.FagName }}</td> 
                                        <td >{{ item.WbbGrossWeight }}</td>                                      
                                    </tr>  
                                </tbody>                                    
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-card>
</template>
<script>
    import moment from 'moment';
    import VueHtml2pdf from 'vue-html2pdf'
    import jsPDF from 'jspdf';
    export default{ 
        props:{
            referralGuideSelected: {
				type: Array,
				default: null,
			},
        },
        components:{
            VueHtml2pdf
        },
        data(){
            return{
                
            }
        },
        methods:{
            downloadPdf(){
                // this.$refs.html2Pdf.generatePdf();
                const contentToExport = this.$refs.testHtml;

                const doc = new jsPDF({
                    unit: 'mm',
                    format: 'a4',
                    orientation: 'portrait'
                });

                const fontSize = 8;
                doc.setFontSize(fontSize);
                
                const margins = { top: 10, bottom: 10, left: 10, right: 10 };
                
                const columnWidth = [[100, 150,100,150],[70,70,70,70,70]];

                const tables = contentToExport.querySelectorAll('table');

                doc.html(contentToExport, {
                    callback: () => {
                        doc.save("impresion.pdf");
                    },
                    x: margins.left,
                    y: margins.top,
                    html2canvas: {
                        scale: 0.3 // Escalar el contenido para reducir el tamaño
                    }
                });     
            },
           
            formatDate(date)
            {
                return moment(date).format('DD/MM/YYYY');
            }
        },
        mounted(){
            
        }
    }
</script>

<style>

.table-container
{
    border: solid 2px black;
    border-radius: 10px;
    width: 99% !important;
    /* border-collapse: collapse; */
}

.table-header th{
    width: 100px;
    border-bottom: 2px solid black;
    font-family: Arial Black, Helvetica, sans-serif;
    font-size: 9px;
    font-weight: bold;
    line-height: 20px;
    text-align: center;
}

.table-body td{
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 9px;
    line-height: 20px;
    padding: 5px;
    font-weight: bolder;
}

.table-body td:first-child{
    text-align: left;
    font-family: Arial Black, Helvetica, sans-serif;
    font-weight: 700;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}


.modal-content {
  background-color: #fff;
  width: 210mm; /* Ancho de documento A4 */
  height: 297mm; /* Alto de documento A4 */
  padding: 20mm; /* Márgenes internos */
  border: 1px solid #000;
}

.table-header{
    border: solid 2px black;
    border-radius: 10px;
    width: 99% !important;
}
.table-header td + td {
    
    border-left: 2px solid black; /* Ajusta el color y el grosor de la línea según sea necesario */
    padding-left: 20px; /* Añade un espacio adicional a la izquierda para separar el contenido de la línea */
}
</style>